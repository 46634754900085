import React from "react";
import styled from "@emotion/styled";
import { syncStatusState } from "../atoms";
import { useRecoilState } from "recoil";
import { sync } from "../services/sync";
import DataWorker from "../data.worker.js";
import { formatISO, parseISO } from "date-fns";
import { Box, Button, IconButton, Spinner } from "@chakra-ui/core";
import { colors } from "../custom-theme";
import { FiRefreshCw, FiWifiOff } from "react-icons/fi";
import PubSub from "pubsub-js";
import { useConnection } from "../common/hooks";
import { MESSAGES } from "../common/const";
import { CgDebug } from "react-icons/cg";
import { Link } from "react-router-dom";
import { css } from "emotion";

const NetworkState = styled.div`
    display: flex;
    align-items: center;
`;

const NetworkOfflineState = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    color: ${colors.red[400]};
    font-size: 14px;

    svg {
        margin-right: 6px;
    }
`;

var worker = undefined;

const SyncStatus = () => {
    const [status, setStatus] = useRecoilState(syncStatusState);

    const connectionState = useConnection();

    const notifySubscribers = () => {
        console.log("DONE syncing");
        PubSub.publish(MESSAGES.Sync, "DONE");
    };

    const syncWorker = () => {
        console.log("DO SYNC VIA WEBWORKER");

        var stor = localStorage.getItem("token");
        var token = JSON.parse(stor).access_token;

        if (!token) {
            return console.error("No token found, login first");
        }

        var lastSyncDate = localStorage.getItem("last_sync_date");
        var newLastSyncDate = new Date();
        if (lastSyncDate) {
            lastSyncDate = parseISO(JSON.parse(lastSyncDate));
        }

        worker = new DataWorker();
        worker.addEventListener("message", e => {
            if (e.data.done === true) {
                localStorage.setItem("last_sync_date", JSON.stringify(formatISO(newLastSyncDate)));
                notifySubscribers();
            } else if (e.data.type === "localstorage") {
                window.localStorage.setItem(e.data.key, JSON.stringify(e.data.data));
            } else {
                var stat = {
                    status: e.data.status,
                    active: e.data.active
                };
                console.log("worker status", stat);
                setStatus(stat);
            }
        });

        var report = window.localStorage.getItem("visitor_report");
        worker.postMessage({
            token: token,
            lastSyncDate: lastSyncDate,
            config: {
                reports_id: report ? JSON.parse(report).id : null,
                last_sync_topproducts: JSON.parse(
                    window.localStorage.getItem("last_sync_topproducts")
                )
            },
            type: "start_sync"
        });
    };

    const syncUIThread = async () => {
        if (!connectionState.online) {
            console.log("Offline, won't sync");
            return;
        }

        var lastSyncDate = localStorage.getItem("last_sync_date");
        var newLastSyncDate = new Date();
        if (lastSyncDate) {
            lastSyncDate = parseISO(JSON.parse(lastSyncDate));
        }

        var stor = localStorage.getItem("token");
        var token = JSON.parse(stor).access_token;

        if (!token) {
            return console.error("No token found, login first");
        }

        var report = window.localStorage.getItem("visitor_report");

        sync(
            token,
            {
                reports_id: report ? JSON.parse(report).id : null,
                last_sync_topproducts: JSON.parse(localStorage.getItem("last_sync_topproducts"))
            },
            (status = undefined, active = false) => {
                var stat = {
                    status: status,
                    active: active
                };
                console.log("Sync status: ", stat);
                setStatus(stat);
            },
            (storageKey, storageData) => {
                window.localStorage.setItem(storageKey, JSON.stringify(storageData));
            },
            lastSyncDate
        ).then(() => {
            localStorage.setItem("last_sync_date", JSON.stringify(formatISO(newLastSyncDate)));
            notifySubscribers();
        });
        setStatus({});
    };

    const startSync = async e => {
        if (window.appConfig.syncWebWorker) {
            await syncWorker();
        } else {
            await syncUIThread();
        }
    };

    return (
        <>
            <NetworkState active={!!connectionState.online}>
                <Box color={colors.gray[400]} fontSize="12px" mr={4}>
                    v{window.appConfig.version}
                </Box>
                {!connectionState.online && (
                    <NetworkOfflineState>
                        <FiWifiOff />
                        <span>Synchroniseren kan enkel online</span>
                    </NetworkOfflineState>
                )}
                {!!connectionState.online && (
                    // Sync (on UI thread)
                    <Button
                        onClick={startSync}
                        isLoading={status.active}
                        loadingText="Synchroniseren"
                        leftIcon={FiRefreshCw}
                        size="sm"
                    >
                        Synchroniseren
                    </Button>
                )}
                <IconButton as={Link} ml={3} to={"/debug"} size="sm" icon={CgDebug}></IconButton>
            </NetworkState>
            {status.active && (
                <div
                    className={css`
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100vw;
                        height: 100vh;
                        z-index: 10000;
                        background: rgba(0, 0, 0, 0.5);
                    `}
                >
                    <div
                        className={css`
                            background: white;
                            padding: 20px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        `}
                    >
                        <Spinner
                            thickness="2px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="red.500"
                            size="sm"
                            mr={4}
                        />
                        {status.status}
                    </div>
                </div>
            )}
        </>
    );
};

export { SyncStatus };
