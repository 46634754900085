import React, { useState } from "react";
import { useLocation, Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import { css } from "emotion";
import { colors } from "../../custom-theme";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/core";
import qs from "qs";
import { CompanyFiche } from "../../pages/company/company-fiche";
import { CompanyVisits } from "../../pages/company/company-visits";
import { CompanyTopProducts } from "../../pages/company/company-top-products";
import { CompanyFicheEdit } from "../../pages/company/company-fiche-edit";
import { FiFileText, FiCalendar, FiShoppingBag, FiUser, FiDollarSign } from "react-icons/fi";
import { useLocationChange, useQueryParams } from "../../common/hooks";
import { CompanyOrders } from "../../pages/company/company-orders";
import { CompanyContacts } from "../../pages/company/company-contacts";

const CompanyTabsStyles = css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-height: 0;
`;

const CompanyTabStyles = css`
    padding: 11px 24px !important;
    background-color: ${colors.gray[300]};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: ${colors.gray[600]};

    svg {
        width: 18px;
        height: 18px;
    }

    &[aria-selected="true"] {
        background-color: white;
        color: ${colors.gray[800]};
    }

    &:not(:last-child) {
        margin-right: 6px;
    }
`;

const CompanyTabPanelsStyles = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    min-height: 0;

    &:focus,
    &:active {
        outline: none;
    }

    > div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 0;
    }
`;

const CompanyTabs = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = useQueryParams();
    const [tabIndex, setTabIndex] = useState(0);
    const match = useRouteMatch();

    useLocationChange(() => {
        // set correct tab
        var tab = qs.parse(location.search.substr(1)).tab;
        if (tab) {
            setTabIndex(parseInt(tab));
        }
    });

    const handleTabsChange = index => {
        setTabIndex(index);
        var tmp = { ...queryParams, tab: index };
        var n = "?" + qs.stringify(tmp);
        history.push(n);
    };

    return (
        <Tabs
            variant="unstyled"
            className={CompanyTabsStyles}
            index={tabIndex}
            onChange={handleTabsChange}
        >
            <TabList css={{ flexShrink: 0 }}>
                <Tab className={CompanyTabStyles}>
                    <FiFileText />
                </Tab>
                <Tab isDisabled={match.params.id === "new"} className={CompanyTabStyles}>
                    <FiCalendar />
                </Tab>
                <Tab isDisabled={match.params.id === "new"} className={CompanyTabStyles}>
                    <FiShoppingBag />
                </Tab>
                <Tab isDisabled={match.params.id === "new"} className={CompanyTabStyles}>
                    <FiUser />
                </Tab>
                <Tab isDisabled={match.params.id === "new"} className={CompanyTabStyles}>
                    <FiDollarSign />
                </Tab>
            </TabList>

            <TabPanels className={CompanyTabPanelsStyles}>
                <TabPanel>
                    <Switch>
                        <Route exact path={`/companies/:id/edit`}>
                            <CompanyFicheEdit />
                        </Route>
                        <Route path={`/companies/:id`}>
                            <CompanyFiche />
                        </Route>
                    </Switch>
                </TabPanel>
                <TabPanel>
                    {match.params.id !== "new" && <CompanyVisits companyId={match.params.id} />}
                </TabPanel>
                <TabPanel>
                    {match.params.id !== "new" && <CompanyOrders companyId={match.params.id} />}
                </TabPanel>
                <TabPanel>
                    <CompanyContacts />
                </TabPanel>
                <TabPanel>
                    {match.params.id !== "new" && (
                        <CompanyTopProducts companyId={match.params.id} />
                    )}
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export { CompanyTabs };
