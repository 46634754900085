import { db } from "./db";
import { SYNCSTATUS } from "../common/const";

async function processCompanyPrices(items, companyId, companyLineDiscount) {
    for (let item of items) {
        /** @type import("dexie").Table */
        var baseQuery = db.specialprices;

        var newPrice = null;
        var discountPercentage = null;

        // step 1 - speciale prijs "artikel voor klant"
        // ex: barcode 5905339088215 - KANLUX rolf-JQ opbouw detector 360° 15m WIT IP20 // OMNITEC SP
        var specialPrice = await baseQuery
            .where({ companies_id: companyId, products_id: item.id })
            .first();
        if (specialPrice) {
            newPrice = specialPrice.price;
        }

        // step 2 - speciale prijs "korting merk"
        if (!newPrice && !!item.brand_id) {
            // ex. 5413736277281 - NIKO hydro wisselschakelaar 10a met schroefklemmen - PREMABO // BATICENTRE NV
            var brandCompanyPrice = await baseQuery
                .where({ companies_id: companyId, brand_id: item.brand_id })
                .first();

            if (brandCompanyPrice) {
                discountPercentage = brandCompanyPrice.discount;
                newPrice = item.price * (1 - discountPercentage / 100);
            } else {
                // THE BELOW DOES NOT EXIST IN THE DB. (although we got the information this exists)
                // var brandPrice = await baseQuery
                //     .where({ brand_id: item.brand_id, companies_id: "" })
                //     .first();
                // if (brandPrice) {
                //     discountPercentage = brandPrice.discount;
                //     newPrice = item.price * 1 - discountPercentage / 100;
                // }
            }
        }

        // step 3 - gewone prijs en lijnkorting klant
        if (!newPrice && companyLineDiscount) {
            discountPercentage = companyLineDiscount;
            newPrice = item.price * (1 - discountPercentage / 100);
        }

        // process result of price search
        if (newPrice) {
            item.originalPrice = item.price;
            item.price = newPrice;
            item.discountPercentage = discountPercentage;
        }
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function listProducts(group, subgroup, search, companyId, companyLineDiscount) {
    return await db.transaction("r", [db.products, db.specialprices], async () => {
        /** @type import("dexie").Table */
        var query = db.products;

        var items;
        if ((group || subgroup) && !search) {
            // first do indexed filtering on the groups, and then further filter non-indexed on search (more narrow result set)
            var groupFilter = {
                is_light: 0
            };
            if (group) groupFilter.productgroup_id = parseInt(group);
            if (subgroup) groupFilter.productsubgroup_id = parseInt(subgroup);

            query = query.where(groupFilter);
            query = query.toArray();

            items = await query;
        } else {
            query = query.where("name_search").startsWithIgnoreCase("0__" + search);
            // query = query.filter(
            //     x =>
            //         !x.is_light ||
            //         (x.is_light &&
            //             (x.code.toUpperCase() === search.toUpperCase() ||
            //                 x.barcode.toUpperCase() === search.toUpperCase()))
            // );
            query = query.limit(500).toArray();
            items = await query;

            // add the exact matches for light products
            var extras = await db.products
                .where("code")
                .equals(search)
                .or("barcode")
                .equals(search)
                .toArray();

            for (var x of extras) {
                if (!items.find(z => z.id === x.id)) {
                    items.push(x);
                }
            }
        }

        if (companyId) {
            await processCompanyPrices(items, companyId, companyLineDiscount);
        }

        return items;
    });
}

async function listGroups(parent) {
    var p = parent ? parseInt(parent) : "-";
    var query = db.productgroups.where({ parent_id: p }).sortBy("name_upper");
    var items = await query;
    return items;
}

async function getProduct(id) {
    var tmp = await db.products.get(parseInt(id));
    return tmp;
}

// async function getChangedCompanies() {
//     var localItems = await db.companies
//         .where("sync_status")
//         .notEqual(SYNCSTATUS.Synced)
//         .toArray();

//     return localItems;
// }

async function insertOrUpdateProduct(dto) {
    await db.products.put({
        ...dto,
        sync_status: SYNCSTATUS.New,
        name_upper: dto.name.toUpperCase()
    });
}

async function deleteProduct(id) {
    await db.products.delete(parseInt(id));
}

export {
    listProducts,
    insertOrUpdateProduct,
    getProduct,
    deleteProduct,
    listGroups,
    processCompanyPrices
};
