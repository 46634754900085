import React, { useCallback, useEffect, useState } from "react";
import { TabContent } from "../../components/tab-content";
import { Spinner } from "@chakra-ui/core";
import { db } from "../../services/db";
import { Table } from "../../components/table";
import { formatCurrency, formatDate } from "../../helpers/format";
import { css } from "emotion";

const CompanyTopProducts = ({ companyId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetch() {
            if (!companyId || companyId === "new") return;
            var items = await db.topproducts
                .where("companies_id")
                .equals(parseInt(companyId))
                .reverse()
                .sortBy("revenue");

            for (var top of items) {
                var product = await db.products
                    .where("id")
                    .equals(top.products_id)
                    .first();
                top.product = product;
            }

            setData(items);
            setLoading(false);
        }
        fetch();
    }, [companyId]);

    const columns = React.useMemo(() => [
        {
            Header: "Product",
            accessor: "product.name"
        },
        {
            Header: "Omzet",
            isNumber: true,
            accessor: "revenue",
            Cell: ({ row }) => (
                <div
                    className={css`
                        text-align: right;
                    `}
                >
                    {formatCurrency(row.values.revenue)}
                </div>
            )
        },
        {
            Header: "Laatst verkocht",
            accessor: "last_sold",
            Cell: ({ row }) => (
                <div
                    className={css`
                        text-align: right;
                    `}
                >
                    {formatDate(row.values.last_sold)}
                </div>
            )
        }
    ]);

    const handleSort = useCallback(sortBy => {}, []);

    return (
        <>
            {loading && (
                <TabContent>
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="red.500"
                        size="md"
                    />
                </TabContent>
            )}
            {!loading && (
                <TabContent title="Meest verkochte producten">
                    <Table columns={columns} data={data} onSort={handleSort} />
                </TabContent>
            )}
        </>
    );
};

export { CompanyTopProducts };
