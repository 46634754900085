import React, { useRef, useState } from "react";
import { css } from "emotion";
import { ProductLineItem } from "../../components/product/product-line-item";
import styled from "@emotion/styled";
import {
    Button,
    useDisclosure,
    ButtonGroup,
    IconButton,
    InputGroup,
    InputRightElement,
    Input,
    Box
} from "@chakra-ui/core";
import produce from "immer";
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from "@chakra-ui/core";
import { FiShoppingCart, FiX, FiSearch } from "react-icons/fi";
import { FiCamera } from "react-icons/fi";
import { db } from "../../services/db";
import { useCart, useCurrentVisit } from "../../common/current-visit";
import { CameraScannerPanel } from "./camera-scanner-panel";
import { useScannerItems } from "../../common/hooks";
import { composeNextScannerItems } from "./compose-next-scanner-items";

const ModalContentStyles = css`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 100%;
    overflow: hidden;
`;

const ModalBodyStyles = css`
    display: flex;
    flex-wrap: wrap;
    min-height: 0;
    background-color: #e9eef5;
`;

const CameraScannerContent = styled.div`
    flex: 1;
    min-height: 0;
    position: relative;
    height: 100%;
    padding: 20px;
    padding-top: 76px;
    display: flex;
    flex-direction: column;
`;

const CameraScannerBody = styled.div`
    position: relative;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);

    .no-products {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
    }
`;

const CameraScanner = props => {
    const [search, setSearch] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [cart, setCart] = useCart();
    const [currentVisit] = useCurrentVisit();
    const [scannerItems, setScannerItems] = useScannerItems();
    const searchRef = useRef();

    const handleCountChange = (lineItem, count) => {
        const next = produce(scannerItems, draft => {
            var tmp = draft.find(x => x.product.id === lineItem.product.id);
            tmp.count = count;
        });
        setScannerItems(next);
    };

    const handleRemove = lineItem => {
        const next = produce(scannerItems, draft => {
            return draft.filter(x => x.product.id !== lineItem.product.id);
        });
        setScannerItems(next);
    };

    const handleAddToCart = () => {
        const next = produce(cart, draft => {
            for (let line of scannerItems) {
                var tmp = draft.items.find(x => x.product.id === line.product.id);
                if (tmp) tmp.count += line.count;
                else draft.items.unshift({ product: line.product, count: line.count });
            }
        });
        setCart(next);
        setScannerItems([]);
        onClose();
    };

    const handleBarcodeSearchSubmit = async () => {
        /** @type import("dexie").Table */
        var query = db.products;
        var c = await query
            .where("barcode")
            .equals(search)
            .count();
        if (c) {
            var next = await composeNextScannerItems(search, scannerItems, cart);
            if (next) {
                setScannerItems(next);
            }

            setSearch("");
        } else {
            alert("Product niet gevonden: " + search);
            setSearch("");
        }
    };

    const handleKeyPress = e => {
        if (e.charCode == 13) {
            //enter
            handleBarcodeSearchSubmit();
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="full" initialFocusRef={searchRef}>
                <ModalOverlay />
                <ModalContent className={ModalContentStyles}>
                    <ModalCloseButton zIndex="10000" top="20px" right="20px" />
                    <ModalBody className={ModalBodyStyles} padding="0">
                        {isOpen && <CameraScannerPanel></CameraScannerPanel>}
                        <CameraScannerContent>
                            <div
                                className={css`
                                    position: absolute;
                                    top: 20px;
                                    left: 20px;
                                `}
                            >
                                <InputGroup width="200px">
                                    <InputRightElement>
                                        {!search && <Box as={FiSearch} size="32px" padding="1" />}
                                        {!!search && (
                                            <Box
                                                className={css`
                                                    cursor: pointer;
                                                `}
                                                as={FiX}
                                                size="32px"
                                                padding="1"
                                                onClick={() => setSearch("")}
                                            />
                                        )}
                                    </InputRightElement>
                                    <Input
                                        ref={searchRef}
                                        value={search}
                                        onChange={e => setSearch(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        rounded="5px"
                                        type="text"
                                        placeholder="Barcode zoeken..."
                                    />
                                </InputGroup>
                            </div>
                            <CameraScannerBody>
                                {scannerItems.map((item, idx) => (
                                    <ProductLineItem
                                        key={idx}
                                        lineItem={item}
                                        onRemove={handleRemove}
                                        onCountChange={handleCountChange}
                                    />
                                ))}
                                {!scannerItems.length && (
                                    <span className="no-products">
                                        Scan een product om toe te voegen.
                                    </span>
                                )}
                            </CameraScannerBody>
                            <ButtonGroup size="sm" marginLeft="auto">
                                <Button
                                    variantColor="green"
                                    leftIcon={FiShoppingCart}
                                    onClick={handleAddToCart}
                                >
                                    Toevoegen
                                </Button>
                            </ButtonGroup>
                        </CameraScannerContent>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <IconButton
                ariaLabel="Scan barcode"
                variantColor="blue"
                icon={FiCamera}
                onClick={onOpen}
                {...props}
            />
        </>
    );
};

export { CameraScanner };
