import produce from "immer";
import { db } from "../../services/db";
import { processCompanyPrices } from "../../services/product-service";

const composeNextScannerItems = async (barcode, scannerItems, cart) => {
    // todo: deduplicate
    // already in list?
    var existing = scannerItems.find(x => x.code === barcode);
    if (existing) {
        const next = produce(scannerItems, draft => {
            var tmp = draft.find(x => x.code === barcode);
            tmp.count = tmp.count + 1;
        });

        return next;
    } else {
        // search product
        var product = await db.products.where({ barcode: barcode }).first();
        await processCompanyPrices(
            [product],
            cart.company.id,
            cart.company.line_discount
        );
        if (!product) {
            alert("Geen product gevonden met barcode " + barcode);
            return null;
        }
        const next = produce(scannerItems, draft => {
            draft.unshift({
                code: barcode,
                product: product,
                count: 1
            });
        });

        return next;
    }
};

export { composeNextScannerItems };
