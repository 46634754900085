import React, { useState, useEffect } from "react";
import { LngLatBounds } from "mapbox-gl";
import ReactMapboxGl, { Marker, MapContext, Cluster } from "react-mapbox-gl";
import { css } from "emotion";
import { useToast } from "@chakra-ui/core";
import { queryCompanies } from "../../services/company-service";
import { useHistory } from "react-router-dom";
import { useConnection } from "../../common/hooks";
import { FiUser, FiWifiOff } from "react-icons/fi";
import styled from "@emotion/styled";
import { colors } from "../../custom-theme";
import { getCurrentLocation } from "../../common/location";
import { BiCurrentLocation, BiBuilding } from "react-icons/bi";
import { useMemo } from "react";
import cl from "classnames";
import { getUserInfo } from "../../common/user-info";
import Axios from "axios";

const NetworkOfflineState = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    color: ${colors.red[400]};
    font-size: 14px;

    svg {
        margin-right: 6px;
    }
`;

const clusterMarker = (coordinates, pointCount) => {
    var k = coordinates[0].toString() + coordinates[1].toString();
    return (
        <Marker key={k} coordinates={coordinates} className={cl(MarkerStyles, "company")}>
            <div>
                <BiBuilding size="20px" />
                <span>{pointCount}</span>
            </div>
        </Marker>
    );
};
const userClusterMarker = (coordinates, pointCount) => {
    var k = coordinates[0].toString() + coordinates[1].toString();
    return (
        <Marker key={k} coordinates={coordinates} className={cl(MarkerStyles, "user")}>
            <div>
                <FiUser size="20px" />
                <span>{pointCount}</span>
            </div>
        </Marker>
    );
};

const MarkerStyles = css`
    div {
        display: flex;
        align-items: center;
        height: 30px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 15px;
        background-color: white;

        svg {
            margin-right: 6px;
        }

        span {
            font-size: 14px;
            font-weight: 500;
        }
    }
    &.user {
        div {
            color: white;
            background-color: ${colors.green[500]};
        }
    }
    &.company {
        div {
            background-color: white;
        }
    }
`;

const CurrentLocationStyles = css`
    i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: white;
        background-color: ${colors.blue[500]};
    }
`;

const getBounds = (companies, currentLocation) => {
    if (companies.length) {
        let bounds = new LngLatBounds();

        for (let company of companies) {
            if (company.coordinates) {
                bounds.extend(company.coordinates);
            }
        }

        if (currentLocation) {
            bounds.extend(currentLocation);
        }

        return bounds.isEmpty() ? null : bounds;
    }

    return null;
};

const CompaniesMap = () => {
    const connectionState = useConnection();
    const [currentLocation, setCurrentLocation] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);
    const history = useHistory();
    const toast = useToast();
    let mounted = true;

    const Map = useMemo(
        () =>
            ReactMapboxGl({
                accessToken: window.appConfig.mapboxToken,
                maxZoom: 20
            }),
        []
    );

    const bounds = getBounds(companies, currentLocation); //
    // const bounds = useMemo(() => getBounds(companies, currentLocation), [
    //     companies,
    //     currentLocation
    // ]);

    useEffect(() => {
        async function fetchCompanies() {
            var companies = (await (await queryCompanies()).toArray())
                .filter(x => !!x.location && !!x.location.coordinates)
                .map(x => ({
                    id: x.id,
                    name: x.name,
                    coordinates: x.location.coordinates
                }));
            setCompanies(companies);

            //
            var res = await Axios.get("/api/user/map");
            var otherUsers = res.data.filter(x => !!x.last_location && x.id !== getUserInfo().id);
            if (mounted) setUsers(otherUsers);
        }
        async function fetchLocation() {
            try {
                var pos = await getCurrentLocation({
                    maxAge: 0,
                    timeout: 20000
                });
                if (mounted) setCurrentLocation([pos.coords.longitude, pos.coords.latitude]);
            } catch (error) {
                toast({
                    title: "Geolocatie",
                    description: "We konden je locatie niet bepalen.",
                    status: "error",
                    isClosable: true
                });
            }
        }
        if (connectionState.online) {
            fetchCompanies();
            fetchLocation();
        }
    }, [connectionState]);

    useEffect(() => {
        return () => (mounted = false);
    }, []);

    const onSelectCompany = company => e => {
        console.log("clicked ON COMPANY ", company);
        history.push(`/companies/${company.id}`);
    };

    return (
        <>
            {!connectionState.online && (
                <NetworkOfflineState>
                    <FiWifiOff />
                    <span>De kaart is enkel online te bekijken</span>
                </NetworkOfflineState>
            )}

            {connectionState.online && (
                <Map
                    style="mapbox://styles/mapbox/streets-v9"
                    containerStyle={{
                        height: "100%",
                        width: "100%"
                    }}
                    center={[3.6911379, 51.0823113]}
                    fitBounds={bounds}
                    fitBoundsOptions={{ duration: 0, padding: 60 }}
                    renderChildrenInPortal={true}
                    flyToOptions={{ speed: 0 }}
                >
                    <MapContext.Consumer>
                        {map => {
                            map.on("load", () => {
                                setCenter(map.getCenter());
                            });
                        }}
                    </MapContext.Consumer>
                    <Cluster radius={100} zoomOnClick={true} ClusterMarkerFactory={clusterMarker}>
                        {companies.map((company, idx) => (
                            <Marker
                                key={company.id}
                                coordinates={company.coordinates}
                                onClick={onSelectCompany(company)}
                                className={cl(MarkerStyles, "company")}
                            >
                                <div>
                                    <BiBuilding size="20px" />
                                    <span>{company.name}</span>
                                </div>
                            </Marker>
                        ))}
                    </Cluster>
                    <Cluster
                        radius={100}
                        zoomOnClick={true}
                        ClusterMarkerFactory={userClusterMarker}
                    >
                        {users.map((item, idx) => (
                            <Marker
                                key={"u_" + item.id}
                                coordinates={item.last_location.coordinates}
                                className={cl(MarkerStyles, "user")}
                            >
                                <div>
                                    <FiUser size="20px" />
                                    <span>{item.name}</span>
                                </div>
                            </Marker>
                        ))}
                    </Cluster>
                    {currentLocation && (
                        <Marker
                            key="currentLocation"
                            coordinates={currentLocation}
                            anchor="center"
                            className={CurrentLocationStyles}
                        >
                            <i>
                                <BiCurrentLocation size="20px" />
                            </i>
                        </Marker>
                    )}
                </Map>
            )}
        </>
    );
};

export { CompaniesMap };
